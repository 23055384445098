@import "./Colors.scss";
@import "./MuiMediaQueries.scss";

::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: $gray;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#contentArea {
    background-color: #f1f9f7;
    header {
        display: none;
    }
}
.WelcomePageBgColor {
    background-color: #ffffff !important;
}
.DashboardSheet {
    max-width: 783px;
    flex: 0 0 100%;
    margin: 20px auto 30px;
    .MuiTypography-h6 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 10px;
        line-height: 1;
    }
    .DashboardMiddleLogo {
        img {
            max-width: 120px;
            margin-bottom: 7px;
        }
    }
    .HeadingText {
        margin-bottom: 32px;
        h2 {
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 24px;
            span {
                font-weight: 900;
            }
        }
        h5 {
            font-weight: 500;
            font-size: 28px;
            @include sm-down {
                font-size: 20px;
                margin-top: 10px;
            }
            span {
                font-weight: 900;
            }
        }
        p {
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            color: $gray;
            line-height: normal;
            @include sm-down {
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }
    .MuiCard-root {
        box-shadow: none;
        overflow: inherit;
        .MuiCardContent-root {
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
            min-height: 416px;
            @include md-down {
                min-height: 280px;
            }
        }
    }
}
.sideBar {
    .MuiListItem-button {
        margin: 10px 0;
        &:hover {
            border-radius: 8px;
            background-color: $lightGray !important;
        }
        .MuiListItemText-root {
            .MuiTypography-root {
                font-size: 16px;
                text-transform: capitalize;
                text-align: left;
                color: $gray;
                font-weight: 500;
            }
        }
        &.Mui-selected {
            background-color: $darkGreen !important;
            border-radius: 8px;
            &:hover {
                background-color: $darkGreen !important;
            }
            .MuiTypography-root {
                color: $white;
            }
            img {
                filter: brightness(0) invert(1);
            }
        }
        .MuiListItemAvatar-root {
            min-width: 32px;
            margin-right: 10px;
            .MuiAvatar-root {
                width: 100%;
                height: auto;
                img {
                    width: 32px;
                    height: auto;
                }
            }
        }
    }
    .MuiCollapse-wrapperInner {
        border-left: 1px solid #ccccd1;
        margin-left: 34px;
        @include sm-down {
            margin-left: 18px;
        }
        .MuiListItem-button {
            padding: 3px 0 0 15px;
            border-radius: 0;
            @include sm-down {
                padding-left: 15px;
            }
            margin: 0;
            &:hover {
                background-color: transparent !important;
                span {
                    color: $darkGreen !important;
                }
            }
        }
    }
    .MuiCollapse-hidden {
        display: none;
    }
    .MuiList-root {
        & > .MuiListItem-button {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
.ESP {
    .MuiBox-root {
        @include sm-down {
            margin-bottom: 30px;
        }
    }
    img {
        max-height: 165px;
        object-fit: cover;
        @include sm-down {
            max-height: 65px;
        }
    }
    h5 {
        margin-bottom: 7px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        color: $black;
    }
    p {
        font-size: 18px;
        font-weight: 300;
        color: $black;
        margin-bottom: 22px;
        line-height: 1.2;
        min-height: 65px;
        @include lg-down {
            min-height: 87px;
        }
        @include md-down {
            min-height: auto;
        }
    }
    .MuiButtonBase-root {
        &.GreenBtn {
            font-size: 16px;
        }
    }
}
.serviceList {
    .MuiListItem-root {
        background-color: $green;
        padding: 14px 16px 14px 16px;
        border-radius: 8px;
        margin-bottom: 8px;
        @include sm-down {
            padding: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: $btnHover;
        }
        .MuiListItemText-root {
            margin: 0;
            .MuiListItemText-primary {
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                line-height: 1;
                font-family: Lato;
                color: $black;
            }
        }
    }
    &.OrangeBt {
        .MuiListItem-root {
            background-color: $orange;
            color: $white;
            .MuiListItemText-root {
                margin: 0;
                .MuiListItemText-primary {
                    color: $white;
                }
            }
        }
    }
}
.MuiButtonBase-root {
    &.GreenBtn {
        background-color: #70d9b8 !important;
        width: 100%;
        padding: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        padding: 11px 16px;
        line-height: 1.3;
        color: $black;
        text-transform: capitalize;
        box-shadow: none;
        border-radius: 8px;
        font-family: Lato, Arial !important;
        &:hover {
            background-color: $btnHover !important;
        }
    }
    &.OrangeBtn {
        background-color: $orange !important;
        padding: 13px 16px 13px 16px;
        min-width: 184px;
        box-shadow: none;
        color: $white;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        line-height: 1;
        text-transform: capitalize;
        &:hover {
            background-color: $orange !important;
        }
    }
}
.pageHeading {
    margin-bottom: 40px;
    @include md-down {
        margin-top: 40px;
    }
    h2 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $black;
        margin-bottom: 8px;
        @include sm-down {
            font-size: 20px;
        }
    }
    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        color: $black;
        @include sm-down {
            font-size: 16px;
        }
    }
}
.ThemeAccordion {
    margin-bottom: 15px;
    &.MuiAccordion-root {
        border-left: 8px solid $orange;
        box-shadow: none !important;
        &::before {
            display: none;
        }
        &.Mui-expanded {
            &::before {
                opacity: 1;
                top: 32px;
            }
        }
        h2 {
            color: $black !important;
            font-weight: 600 !important;
            line-height: 1.4;
            @include md-down {
                font-size: 16px;
                flex-shrink: 1;
                line-height: 1.3;
            }
        }
        & > .MuiAccordionSummary-root {
            .MuiIconButton-label {
                & > svg {
                    color: $gray;
                    font-size: 40px;
                    @include sm-down {
                        font-size: 32px;
                    }
                }
            }
            .MuiAccordionSummary-content {
                &.Mui-expanded {
                    margin: 10px 0 0px;
                }
            }
        }
        .MuiAccordionDetails-root {
            border: none !important;
            @include md-down {
                margin: 7px 0px;
            }
            @include sm-down {
                padding-top: 0;
                padding-bottom: 0;
            }
            .MuiSlider-markLabel {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                top: 38px;
                @include lg-down {
                    font-size: 13px;
                    left: 56px;
                    display: inline-block;
                }
                @include md-down {
                    margin-bottom: 13px;
                    top: auto;
                }
                @include sm-down {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .MuiSlider-root {
                color: $gray2;
                @include md-down {
                    min-height: 280px;
                    margin-top: 10px;
                    margin-bottom: 15px;
                }
                @include sm-down {
                    margin-top: 25px;
                }
                .MuiSlider-rail {
                    height: 4px;
                    @include md-down {
                        height: 270px;
                        margin-left: 10px;
                    }
                }
                .MuiSlider-thumb {
                    width: 24px;
                    height: 24px;
                    margin-top: -12px;
                    color: $gray2;
                    margin-left: 0;
                    color: $green;
                    @include md-down {
                        margin-bottom: 0;
                    }
                }
                .MuiSlider-mark {
                    width: 24px;
                    height: 24px;
                    margin-top: -12px;
                    border-radius: 50%;
                }
            }
            span {
                &.MuiCheckbox-root {
                    svg {
                        font-size: 27px;
                    }
                }
            }
            .MuiAccordionDetails-root {
                padding: 0;
            }
            .MuiAccordion-root {
                &::before {
                    display: none;
                }
                .MuiAccordionSummary-content {
                    h3 {
                        color: $gray;
                        font-weight: 600;
                        line-height: 1.5;
                        flex-shrink: inherit;
                        @include md-down {
                            font-size: 16px;
                        }
                        @include sm-down {
                            font-size: 16px;
                            flex-grow: 1;
                        }
                    }
                }
                &.Mui-expanded {
                    .MuiAccordionSummary-content {
                        h3 {
                            color: $black;
                        }
                    }
                }
                .MuiCollapse-root {
                    .MuiSlider-markLabel {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black;
                        top: 38px;
                        @include lg-down {
                            font-size: 13px;
                            left: 56px;
                            display: inline-block;
                        }
                        @include md-down {
                            margin-bottom: 13px;
                            top: auto;
                        }
                        @include sm-down {
                            font-weight: 400;
                            font-size: 14px;
                        }
                    }
                    .MuiSlider-root {
                        color: $gray2;
                        @include md-down {
                            min-height: 280px;
                            margin-top: 10px;
                            margin-bottom: 15px;
                        }
                        .MuiSlider-rail {
                            height: 4px;
                            @include md-down {
                                height: 270px;
                                margin-left: 10px;
                            }
                        }
                        .MuiSlider-thumb {
                            width: 24px;
                            height: 24px;
                            margin-top: -12px;
                            color: $gray2;
                            margin-left: 0;
                            color: $green;
                            @include md-down {
                                margin-bottom: 0;
                            }
                        }
                        .MuiSlider-mark {
                            width: 24px;
                            height: 24px;
                            margin-top: -12px;
                            border-radius: 50%;
                        }
                    }
                    span {
                        &.MuiCheckbox-root {
                            svg {
                                font-size: 27px;
                            }
                        }
                    }
                }
            }
            .MuiAccordion-rounded {
                border-radius: 0;
                box-shadow: none;
                @include sm-down {
                    margin-top: 0;
                }
                .MuiAccordionSummary-root {
                    @include sm-down {
                        padding: 0;
                    }
                }
            }
            .MuiAccordion-root {
                .Mui-focused {
                    background-color: transparent;
                }
            }
        }
    }
    &.MuiAccordion-rounded {
        border-radius: 8px !important;
    }
    .MuiAccordionActions-root {
        margin: 16px;
        padding: 0;
        @include md-down {
            justify-content: center;
        }
        @include sm-down {
            margin: 25px 20px 20px;
        }
        .MuiButtonBase-root {
            &.OrangeBtn {
                padding: 10px 16px;
                min-width: 120px;
                font-size: 16px;
            }
        }
    }
}
.NumberAccordion {
    list-style-type: none;
    counter-reset: css-counter 0;
    @include xl-down {
        padding-left: 72px;
    }
    @include lg-down {
        padding-left: 47px;
    }
    & > .MuiAccordion-root {
        counter-increment: css-counter 1;
        &::before {
            content: counter(css-counter) "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -81px;
            background-color: #fff;
            width: 48px;
            height: 48px;
            z-index: 999;
            opacity: 1;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid $green;
            color: $green;
            font-size: 28px;
            font-weight: 700;
            transition: 0.3s;
            @include lg-down {
                left: -56px;
                width: 38px;
                height: 38px;
                font-size: 20px;
            }
        }
        &.FilledAccordion {
            &:before {
                content: " ";
                display: block;
                width: 11px;
                height: 20px;
                border: solid #ffffff;
                border-top-width: medium;
                border-right-width: medium;
                border-bottom-width: medium;
                border-left-width: medium;
                border-width: 0 4px 4px 0;
                position: absolute;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                border-radius: 0;
                padding: 0px;
                top: 12px;
                left: -62px;
                background-color: transparent;
                transition: 0.3s;
                @include lg-down {
                    border-width: 0 3px 3px 0;
                    left: -41px;
                    top: 18px;
                    width: 9px;
                    height: 17px;
                }
            }
            &::after {
                content: "";
                background-color: $green;
                width: 48px;
                height: 48px;
                z-index: 0;
                opacity: 1;
                display: flex;
                position: absolute;
                top: 0px;
                left: -81px;
                border-radius: 50%;
                transition: 0.3s;
                @include lg-down {
                    left: -56px;
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                    top: 10px;
                }
            }
        }
    }
}
.RadioBtnContent {
    padding: 0 24px;
    flex-grow: 1;
    @include sm-down {
        padding: 0;
    }
    fieldset {
        margin-bottom: 20px;
        @include sm-down {
            margin-bottom: 0;
        }
    }
    span.MuiButtonBase-root {
        margin-right: 9px;
    }
    .MuiFormControlLabel-root {
        @include sm-down {
            margin-right: 0;
        }
    }
    .MuiFormControlLabel-label {
        font-size: 18px;
        font-weight: 600;
        color: $black;
        @include md-down {
            font-size: 16px;
        }
    }
}
.TermsText {
    margin-top: 25px;
    .MuiAlert-standardInfo {
        padding-left: 0;
        padding-right: 0;
    }
    .MuiFormControlLabel-root {
        &:not(:last-child) {
            margin-bottom: 20px;
            @include md-down {
                margin-bottom: 0;
            }
        }
        .MuiFormControlLabel-label {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            color: $black;
            @include md-down {
                font-size: 16px;
            }
        }
    }
}
.MuiAlert-message {
    font-size: 16px;
}
.ACFormContent {
    padding: 0 15px;
    @include sm-down {
        padding: 0 10px;
    }
    .MuiFormControlLabel-root {
        margin-right: 10px;
    }
    .MuiFormControlLabel-label {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color: $black;
        line-height: 1.4;
        @include md-down {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}
.AllComponentGrid {
    flex-basis: 100% !important;
    max-width: 100% !important;
}
.CheckboxList {
    margin-bottom: 10px;
}
.NavigationBar-drawerPaper-5 {
    @include sm-down {
        width: 260px !important;
        padding: 10px !important;
        overflow: hidden;
    }
}
.MuiRadio-colorPrimary {
    &.Mui-checked {
        color: $green;
    }
}
span {
    &.ACLabel {
        font-weight: 600;
        display: inline-block;
        margin-bottom: 20px;
        color: $gray;
        @include sm-down {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
        }
    }
}
#sp-main {
    @include sm-down {
        padding-bottom: 40px;
        align-items: flex-start;
    }
}
img {
    max-width: 100%;
}
.App-appMain-3 {
    padding: 20px;
}
.OrangeBtnList {
    margin-bottom: 30px;
    h4 {
        margin-top: 10px;
    }
}
.NotAvailable{
    font-size: 16px;
    font-weight: 300;
    color: rgb(46, 46, 46);
}
.TextAlignCenter {
    text-align: center;
}

.TextAlignStart {
    text-align: start;
}
@media (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
@media all and (min-width: 900px) and (max-width: 959px) {
    .ThemeAccordion.MuiAccordion-root .MuiAccordionDetails-root .MuiSlider-root .MuiSlider-rail {
        height: 270px !important;
        margin-left: 10px !important;
    }
    .ThemeAccordion.MuiAccordion-root .MuiAccordionDetails-root .MuiSlider-root {
        min-height: 280px;
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .ThemeAccordion.MuiAccordion-root .MuiAccordionDetails-root .MuiSlider-root .MuiSlider-markLabel {
        top: auto !important;
        margin-bottom: 13px;
    }
}
@media all and (max-width: 959px) {
    .Header-menuButton-10 {
        margin-right: 5px !important;
    }
    #contentArea {
        header {
            display: block;
        }
    }
}
@include md-down {
    .serviceList {
        .MuiListItem-root {
            .MuiListItemText-root {
                .MuiListItemText-primary {
                    font-size: 16px;
                    line-height: 1.3;
                    @include sm-down {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .GreenBtn {
        &.MuiButtonBase-root {
            font-size: 16px;
        }
    }
    .Header-menuButton-10 {
        margin-right: 0px !important;
    }
}
@include sm-down {
    .Header-logo-12 {
        height: 40px !important;
    }
    .sideBar {
        .MuiListItem-button {
            padding: 7px;
            margin: 10px 0;
            .MuiListItemAvatar-root {
                min-width: 25px;
                margin-right: 7px;
                .MuiAvatar-root {
                    img {
                        width: 24px;
                    }
                }
            }
            .MuiListItemText-root {
                .MuiTypography-root {
                    font-size: 14px;
                }
            }
        }
    }
}
