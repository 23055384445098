$gray: #8a8a8a;
$gray2: #ccccd1;
$mint: #e4f1ed;
$darkGreen: #336472;
$green: #70d9b8;
$black: #2e2e2e;
$btnHover: #6cbf84;
$lightGray: #edeff1;
$white: #ffffff;
$orange: #fa6c51;
$orangeHover: #f53711;
