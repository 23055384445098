$screen-sm-down: 599px;
$screen-md-down: 899px;
$screen-lg-down: 1199px;
$screen-desktop-down: 1366px;
$screen-xl-down: 1535px;

@mixin sm-down {
    @media (max-width: #{$screen-sm-down}) {
        @content;
    }
}
@mixin md-down {
    @media (max-width: #{$screen-md-down}) {
        @content;
    }
}
@mixin lg-down {
    @media (max-width: #{$screen-lg-down}) {
        @content;
    }
}
@mixin desktop-down {
    @media (max-width: #{$screen-desktop-down}) {
        @content;
    }
}
@mixin xl-down {
    @media (max-width: #{$screen-xl-down}) {
        @content;
    }
}
